var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-ficha noticia"},[_c('a',{staticClass:"volver",on:{"click":_vm.back}},[_c('img',{attrs:{"src":"/img/flecha_salir-azul.svg","alt":""}})]),(_vm.data)?_c('div',{staticClass:"contenedor-evento"},[_c('div',{staticClass:"imagen-principal"},[_c('img',{attrs:{"src":_vm.data.image.url,"alt":_vm.data.title}})]),_c('div',{staticClass:"contenedor-cuerpo"},[_c('div',{staticClass:"cuerpo-real"},[_c('h2',[_vm._v(_vm._s(_vm.data.title))]),_c('h4',{staticClass:"js-fecha"},[_vm._v(_vm._s(_vm._f("date")(_vm.data.date)))]),(_vm.data.category.name)?_c('p',[_c('strong',[_vm._v("Tipo de evento:")]),_vm._v(" "+_vm._s(_vm.data.category.name)+" ")]):_vm._e(),(_vm.data.organizer)?_c('p',[_c('strong',[_vm._v("Organizador:")]),_vm._v(" "+_vm._s(_vm.data.organizer)+" ")]):_vm._e(),(_vm.data.address)?_c('p',[_c('strong',[_vm._v("Lugar:")]),_vm._v(" "+_vm._s(_vm.data.address))]):_vm._e(),(_vm.data.schedule)?_c('p',[_c('strong',[_vm._v("Horario:")]),_vm._v(" "+_vm._s(_vm.data.schedule)+" ")]):_vm._e(),(_vm.data.price)?_c('p',[_c('strong',[_vm._v("Coste:")]),_vm._v(" "+_vm._s(_vm.data.price))]):_vm._e(),(_vm.data.type)?_c('p',[(_vm.data.type == 0)?_c('strong',[_vm._v("General")]):_vm._e(),(_vm.data.type == 1)?_c('strong',[_vm._v("Presencial")]):_vm._e(),(_vm.data.type == 2)?_c('strong',[_vm._v("Online")]):_vm._e()]):_vm._e(),_c('editorjs',{attrs:{"modulos":_vm.data.description}}),(_vm.showinscripcion)?_c('div',{staticClass:"row-start"},[_c('button',{staticClass:"boton-azul",on:{"click":_vm.todown}},[_vm._v("Inscribirse")])]):_vm._e()],1)]),(_vm.showinscripcion)?_c('div',{staticClass:"formulario-inscripcion",attrs:{"id":"formulario"}},[_c('h2',[_vm._v("Inscríbete")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.name),expression:"dataform.name"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"nombre","placeholder":"Nombre*"},domProps:{"value":(_vm.dataform.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataform, "name", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.surnames),expression:"dataform.surnames"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"apellidos","placeholder":"Apellidos*"},domProps:{"value":(_vm.dataform.surnames)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataform, "surnames", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.email),expression:"dataform.email"}],class:classes,attrs:{"autocomplete":"off","type":"email","name":"mail","placeholder":"Email*"},domProps:{"value":(_vm.dataform.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataform, "email", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.phone),expression:"dataform.phone"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"movil","placeholder":"Móvil*"},domProps:{"value":(_vm.dataform.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataform, "phone", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.dni),expression:"dataform.dni"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"dni","placeholder":"DNI*"},domProps:{"value":(_vm.dataform.dni)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataform, "dni", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"numeric|max_value:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.companions),expression:"dataform.companions"}],class:classes,attrs:{"autocomplete":"off","type":"number","name":"companions","placeholder":"Número de acompañantes"},domProps:{"value":(_vm.dataform.companions)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataform, "companions", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.data.type === 0)?_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.type),expression:"dataform.type"}],class:classes,attrs:{"id":"attendance_preference","name":"attendance_preference"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.dataform, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Preferencia de asistencia*")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Presencial")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Online")])]),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataform.privacy_check),expression:"dataform.privacy_check"}],class:classes,attrs:{"type":"checkbox","id":"contact"},domProps:{"checked":Array.isArray(_vm.dataform.privacy_check)?_vm._i(_vm.dataform.privacy_check,null)>-1:(_vm.dataform.privacy_check)},on:{"change":function($event){var $$a=_vm.dataform.privacy_check,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.dataform, "privacy_check", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.dataform, "privacy_check", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.dataform, "privacy_check", $$c)}}}}),_c('label',{attrs:{"for":"contact"}},[_vm._v("Acepto y autorizo a que mis datos sean tratados por la Universidad Francisco de Vitoria, con las finalidades indicadas anteriormente y para remitirme, por cualquier medio, incluidos los electrónicos (SMS, Whatsapp, correo electrónico y redes sociales) las comunicaciones correspondientes.*")]),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-boton"},[_c('div',{staticClass:"contenedor-boton row-start"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v(" Inscribirse ")])])])])]}}],null,false,3708433558)})],1):_vm._e()]):_vm._e(),(_vm.showpopup)?_c('div',{staticClass:"popup"},[(_vm.hasToRegister)?_c('div',{staticClass:"paso"},[_c('span',{staticClass:"close",on:{"click":function($event){_vm.showpopup = !_vm.showpopup}}},[_vm._v("X")]),_vm._m(0),_c('p',[_vm._v(" El email que ha indicado no consta como usuario registrado de Padres UFV. Para apuntarse a este evento, pulse a continuación: ")]),_c('a',{staticClass:"boton-azul",attrs:{"href":_vm.urlAlumni +
          'es/general/encuesta-padres?event_id=' +
          _vm.dataform.event_id,"target":"_blank"}},[_vm._v("Registrarme y apuntarme al evento")])]):_c('div',{staticClass:"paso"},[_c('span',{staticClass:"close",on:{"click":function($event){_vm.showpopup = !_vm.showpopup}}},[_vm._v("X")]),_vm._m(1),_c('p',[_vm._v("Tu inscripción se ha realizado correctamente.")]),_vm._m(2),_vm._m(3),_c('p',[_vm._v("¡Bienvenido a UFV Familias!")]),_c('a',{staticClass:"boton-azul",on:{"click":function($event){_vm.showpopup = false}}},[_vm._v("Aceptar")])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"titulo",staticStyle:{"text-align":"center","margin-bottom":"15px"}},[_c('strong',[_vm._v("¡Atención!")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"titulo",staticStyle:{"text-align":"center"}},[_c('strong',[_vm._v("Enhorabuena")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Te mantendremos informado de todas nuestras actividades por correo electrónico También puedes seguirnos a través de la "),_c('a',{attrs:{"href":"https://padres-ufv.es/"}},[_vm._v("web")]),_vm._v(" e "),_c('a',{attrs:{"target":"_blank","href":"https://www.instagram.com/padresufv/"}},[_vm._v("instagram.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Cualquier consulta puedes escribirnos a "),_c('a',{attrs:{"href":"mailto:familias@ufv.es"}},[_c('u',[_vm._v("familias@ufv.es.")])])])}]

export { render, staticRenderFns }